import { t, Trans } from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Children, useCallback, useEffect, useState, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from '@mui/icons-material';
import moment from 'moment/moment';
import IsMobile from '../../../utils/IsMobile';
import {
  createSearchQuery,
  extractSearcMatchedData,
  generateLunrIndex,
} from '../../../utils/utils';
import { getDateWithGMTInfo } from '../../../utils/date/format';
import Pagination from '../../atoms/Pagination/Pagination';
import SearchInput from '../SearchInput/SearchInput';
import SelectOptions from '../SelectOptions/SelectOptions';
import {
  StyledTableRow,
  StyledTableRowAlternateColor,
} from './UsedComponentsAndImports';
import TableHeader from './components/TableHeader/TableHeader';
import { removeFilterOnClick } from './filtersOperations';
import DataDescription from '../../../pages/equipments/Equipment/components/DataDescription/DataDescription';

import './TableWithPagination.scss';

// TO-DO - Improve data request pagination
// AS IS the UI is requesting all data from start
const TableWithPagination = ({
  title,
  description,
  color,
  headers,
  checkboxHeader,
  checkboxesStatesResp,
  rows,
  cells,
  optionsValue,
  alternateBg,
  simpleTable,
  rangeSelection,
  searchFields,
  columnsObject,
  switchComponent,
  enableFiltersOptions,
  initFilters,
  dataDescription,
  parentFilters,
  hideColumns,
  pagination = {},
  setEquipment = () => {},
  noSearch,
  headerExtract,
  titleExtract,
  hideExract,
  equipment,
}) => {
  const { currentPage, pageSize, totalElements, handlePageChange } = pagination;
  const [page, setPage] = useState(currentPage ?? 0);

  const [rowsPerPage, setRowsPerPage] = useState({
    label: pageSize ?? optionsValue[0],
    value: pageSize ?? optionsValue[0],
  });
  const [searchLunrIdx, setSearchLunrIdx] = useState();

  // TO-DO improve this
  // why 3 variables for rows?
  const [tempVisibleRows, setTempVisibleRows] = useState(rows);
  const [permVisibleRows, setPermVisibleRows] = useState(rows);
  const [visibleRows, setVisibleRows] = useState(rows);

  const [isSorted, setIsSorted] = useState({});
  const [headersSorted, setHeadersSorted] = useState({});
  const [filters, setFilters] = useState({});
  const [checkBoxesStates, setCheckBoxesStates] =
    useState(checkboxesStatesResp);
  const [openPopover, setOpenPopover] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [defaultIndex, setDefaultIndex] = useState(0);

  const [rowIdClicked, setRowIdClicked] = useState();

  const isMobile = IsMobile();
  const rowsLength = totalElements ?? rows.length;

  const columnsHeadersKey = columnsObject
    ? Object.keys(columnsObject).map((l) => l.toLowerCase())
    : null;

  const dataToSelect = parentFilters ? parentFilters.get : filters;

  const getFiltersLength = (obj) => {
    const filtersKeys = Object.keys(obj).filter((el) => el);
    let filtersLengthN = 0;
    filtersKeys.forEach((f) => {
      if (obj[f] && obj[f].values) filtersLengthN += obj[f].values.length;
    });

    return filtersLengthN;
  };

  const filtersLength = getFiltersLength(dataToSelect);

  // initialisation of the Filters State if there is checkboxState
  const initFiltersCheckBoxState = useCallback(() => {
    const filtersInit = {};
    checkboxesStatesResp.forEach((el) => {
      filtersInit[el.header] = {
        values: el.values.map((v) => v.name).sort(),
        isCheckBox: true,
      };
    });
    if (parentFilters) {
      setFilters((f) => {
        return {
          ...f,
          ...parentFilters.get,
        };
      });
    } else {
      setFilters((f) => {
        return {
          ...f,
          ...filtersInit,
        };
      });
    }
  }, [checkboxesStatesResp, parentFilters]);

  const resetStates = () => {
    if (parentFilters) {
      const filtersInit = {};
      checkboxesStatesResp.forEach((el) => {
        filtersInit[el.header] = {
          values: el.values.map((v) => v.name).sort(),
          isCheckBox: true,
        };
      });
      setFilters([]);

      parentFilters.set(filtersInit);
      return;
    }

    setFilters([]);
    setVisibleRows(rows);
    setPermVisibleRows(rows);
    setTempVisibleRows(rows);

    if (checkBoxesStates && checkboxesStatesResp) {
      initFiltersCheckBoxState();
      setCheckBoxesStates(checkboxesStatesResp);
    }
  };

  const updateFilters = useCallback(() => {
    if (
      parentFilters &&
      JSON.stringify(Object.keys(filters)) !==
        JSON.stringify(Object.keys(parentFilters.get))
    ) {
      setFilters(parentFilters.get);
    }
  }, [parentFilters, filters]);

  const updateVisibleRows = useCallback(() => {
    // to be able to search (doesn't work with external pagination)
    // eslint-disable-next-line no-unused-expressions
    !noSearch && setPage(0);

    if (
      getFiltersLength(dataToSelect) === 0 &&
      checkBoxesStates &&
      checkboxesStatesResp
    ) {
      initFiltersCheckBoxState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    simpleTable,
    dataToSelect,
    checkBoxesStates,
    checkboxesStatesResp,
    initFiltersCheckBoxState,
  ]);

  function generateChipChildren(filters, el) {
    return filters[el].values.map((val) => {
      return (
        <Chip
          className="table-with-pagination__chip-stack-chip"
          label={val}
          variant="outlined"
          sx={{
            backgroundColor: 'primary.main',
          }}
          deleteIcon={<CloseIcon />}
          onDelete={() =>
            removeFilterOnClick({
              header: el,
              value: val,
              filters,
              setFilters,
              rows,
              checkBoxesStates,
              setCheckBoxesStates,
              checkboxHeader,
              initCheckBoxState: initFiltersCheckBoxState,
              columnsObject,
              checkboxesStatesRespD: checkboxesStatesResp,
              parentFilters,
              setEquipment,
            })
          }
        />
      );
    });
  }

  // OK -> But can be improved (call abtract function with a clear name/role)
  const updateRows = useCallback(() => {
    try {
      if (
        (rowsLength === rowsPerPage.value || page === rowsLength) &&
        page !== 0
      ) {
        setPage((p) => p - 1);
      }

      if (searchFields && searchFields.length > 0)
        setSearchLunrIdx(generateLunrIndex(searchFields, rows));

      if (parentFilters && enableFiltersOptions) {
        const filtersObj = parentFilters.get;

        if (getFiltersLength(filtersObj) > 0) {
          const filtersArr = Object.keys(filtersObj);
          const data = [...rows];
          const newFilteredDate = [];

          data.forEach((d) => {
            let isEqualToAll = 0;
            filtersArr.forEach((f) => {
              let isEqual = 0;

              if (filtersObj[f].isCheckBox) {
                filtersObj[f].values.forEach((v) => {
                  const key = columnsObject[f];
                  const rowValue = key.search(d) || '';

                  if (rowValue.toLowerCase().includes(v.toLowerCase())) {
                    isEqual = 1;
                  }
                });
                if (isEqual === 1) {
                  isEqualToAll += 1;
                }
              } else {
                filtersObj[f].values.forEach((v) => {
                  const key = columnsObject[f];
                  const rowValue = key.search(d) || '';

                  if (rowValue.toLowerCase().includes(v.toLowerCase())) {
                    isEqual += 1;
                  }
                });
                if (isEqual === filtersObj[f].values.length) {
                  isEqualToAll += 1;
                }
              }
            });
            if (isEqualToAll === filtersArr.length) {
              newFilteredDate.push(d);
            }
          });

          setPermVisibleRows(newFilteredDate);
          setTempVisibleRows(newFilteredDate);
          setVisibleRows((prevVisibleRows) => {
            if (
              !isEqual(prevVisibleRows, newFilteredDate) &&
              !isEqual(rows, newFilteredDate)
            ) {
              setEquipment((prevEquipment) => prevEquipment);
            }
            return newFilteredDate;
          });
        }
      } else {
        setPermVisibleRows(rows);
        setTempVisibleRows(rows);
        setVisibleRows(rows);
      }

      // Initialisation of the filters with checkBoxState if there is no defaultFilters
      if (!initFilters && checkBoxesStates) {
        initFiltersCheckBoxState();
      }

      // Initialisation the the state used for the sorting of the row
      if (columnsHeadersKey) {
        const sortedObj = {};
        columnsHeadersKey.forEach((f) => {
          sortedObj[f] = {
            up: false,
            down: false,
          };
        });
        setIsSorted(sortedObj);

        if (enableFiltersOptions) {
          const openPopoverInit = {};
          columnsHeadersKey.forEach((el) => {
            openPopoverInit[el] = false;
          });
          setOpenPopover(openPopoverInit);
          setHeadersSorted([]);
          headers.forEach((header) => {
            if (columnsHeadersKey.includes(header.toLowerCase())) {
              const resp = headersSorted;
              resp[header.toLowerCase()] = false;
              setHeadersSorted(resp);
            }
          });

          setCheckBoxesStates(checkboxesStatesResp);
        }
      }

      // Initialisation of the filters with checkBoxState if there is a defaultFilters
      if (initFilters && checkBoxesStates) {
        const filtersKeys = Object.keys(initFilters).filter((el) => el);
        const initFiltersState = {
          ...filters,
        };
        checkBoxesStates.forEach((el) => {
          initFiltersState[el.header] = {
            values: el.values.map((v) => v.name).sort(),
            isCheckBox: true,
          };
        });
        filtersKeys.forEach((k) => {
          initFiltersState[k] = {
            values: [...initFilters[k].values],
            isCheckBox: initFilters[k].isCheckBox,
          };
        });

        let filteredRows = [...rows];
        filtersKeys.forEach((f) => {
          initFiltersState[f].values.forEach((v) => {
            const resp = filteredRows.filter((row) => {
              const key = columnsObject[f];
              const rowValue = key.search(row) || '';
              return rowValue.toLowerCase().includes(v.toLowerCase());
            });

            filteredRows = resp;
          });
        });

        setPermVisibleRows(filteredRows.flat());
        setVisibleRows(filteredRows.flat());
        setFilters(initFiltersState);
      }
    } catch (e) {
      console.error(e);
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, headers]);

  useEffect(() => {
    updateFilters();
  }, [updateFilters]);

  useEffect(() => {
    updateVisibleRows();
  }, [updateVisibleRows, visibleRows]);

  useEffect(() => {
    updateRows();
  }, [rows, headers, updateRows]);

  const handleSearchPopOverClick = (event, el) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover((state) => {
      return {
        ...state,
        [el]: true,
      };
    });
  };

  const handleClosePopOver = (init) => {
    setAnchorEl(null);
    const openInit = {};
    columnsHeadersKey.forEach((el) => {
      openInit[el] = false;
    });
    setOpenPopover(openInit);
    if (!init) setVisibleRows(permVisibleRows);
  };

  const popOverId = openPopover ? 'simple-popover' : undefined;

  const handleChangePage = (event, newPage) => {
    const requestPage = newPage - 1;

    setPage(requestPage);

    if (handlePageChange) {
      handlePageChange(requestPage, rowsPerPage.value);
    }
  };

  const handleChangeRowsPerPage = (index, event) => {
    const number = event.target
      ? parseInt(event.target.innerText, 10)
      : event.value;

    if (handlePageChange) {
      handlePageChange(page, number);
    } else {
      if (rowsPerPage.value === number) return;
      if (number > rowsPerPage.value) {
        setPage((e) =>
          Math.round(Math.floor(e / (number / rowsPerPage.value)))
        );
        if (Math.round(Math.floor(page / (number / rowsPerPage.value))) < 1) {
          setPage(0);
        }
      } else {
        setPage((e) => Math.round(e * (rowsPerPage.value / number)));
      }
      if (page < 1) setPage(0);
    }

    setDefaultIndex(index);
    setRowsPerPage({ label: number, value: number });
  };

  const optionsRangeSelect = optionsValue.map((option, index) => {
    return {
      label: option,
      onClick: (e) => handleChangeRowsPerPage(index, e),
    };
  });

  const rowsPerPageOptions = !isMobile && (
    <SelectOptions
      className="table-with-pagination__rows-per-page-options"
      options={optionsRangeSelect}
      defaultIndex={defaultIndex}
    />
  );

  const search = (key) => {
    if (key.search != null) {
      const results = extractSearcMatchedData(
        rows,
        searchLunrIdx.search(createSearchQuery(key.search))
      );
      setVisibleRows(results);
    }
  };

  const handleCheckBoxChange = ({
    header,
    name,
    keyValue,
    checked,
    keysNamesValues,
  }) => {
    const headerObject = checkBoxesStates.find(
      (chck) => chck.header === header
    );

    const headerValues = headerObject.values.map((v) => {
      return {
        ...v,
        value: v.name === name ? !v.value : v.value,
      };
    });

    const updatedHeaderObject = checkBoxesStates.map((el) => {
      if (el.header === header) {
        return {
          ...el,
          values: headerValues,
        };
      }
      return el;
    });
    setCheckBoxesStates(updatedHeaderObject);

    let isInitCheckBoxState = false;
    if (
      !headerValues.every((w) => w.value) &&
      !headerValues.every((w) => !w.value)
    ) {
      const checkboxValues = headerValues
        .map((m) => {
          if (!m.value) {
            return keysNamesValues[m.name];
          }
          return undefined;
        })
        .filter((el) => el !== undefined);

      const filteredArrayWithoutCheckboxValues = tempVisibleRows.filter(
        (f) => !checkboxValues.includes(f[keyValue])
      );

      setVisibleRows(filteredArrayWithoutCheckboxValues);
      setPermVisibleRows(filteredArrayWithoutCheckboxValues);
    } else {
      setVisibleRows(tempVisibleRows);
      setPermVisibleRows(tempVisibleRows);
      if (headerValues.every((w) => !w.value)) {
        const initHeaderObject = checkBoxesStates.map((el) => {
          if (el.header === header) {
            return {
              ...el,
              values: headerValues.map((v) => {
                return {
                  ...v,
                  value: true,
                };
              }),
            };
          }
          return el;
        });
        setCheckBoxesStates(initHeaderObject);
        if (checkBoxesStates) {
          initFiltersCheckBoxState();
        }
        isInitCheckBoxState = true;
      }
    }

    if (!isInitCheckBoxState) {
      if (!checked) {
        const objj = {
          [header]: {
            values: [
              ...filters[header].values.filter((el) => el !== name),
              name,
            ].sort(),
            isCheckBox: true,
          },
        };

        setFilters((f) => {
          return {
            ...f,
            ...objj,
          };
        });
      } else if (filters[header]) {
        const objj = {
          values: filters[header].values.filter((el) => el !== name).sort(),
          isCheckBox: true,
        };
        setFilters((f) => {
          return {
            ...f,
            [header]: objj,
          };
        });
      }
    }
  };

  const filteredHeader = hideColumns
    ? headers.filter((f) => !hideColumns.includes(f))
    : headers;

  const printChip = () => {
    return Object.keys(filters).map((el) => {
      if (
        filters[el]?.isCheckBox &&
        filters[el]?.values?.length ===
          checkboxesStatesResp.find((f) => f.header === el)?.values?.length
      ) {
        return null;
      }

      if (!filters[el] || !filters[el].values) {
        return null;
      }

      // eslint-disable-next-line consistent-return
      return (
        <Stack
          className="table-with-pagination__chip-stack"
          direction="row"
          color="primary.main"
          spacing={1}
          key={`stack-filter-${el}`}
        >
          {filters[el].values.length > 0 && (
            <>
              {!isMobile && (
                <Typography className="table-with-pagination__chip-stack-name">
                  {el} :
                </Typography>
              )}

              {Children.map(generateChipChildren(filters, el), (item) => (
                <>{item}</>
              ))}
            </>
          )}
        </Stack>
      );
    });
  };

  const handleRowClick = (rowIdClicked) => {
    setRowIdClicked((prevId) =>
      prevId !== rowIdClicked ? rowIdClicked : null
    );
  };

  const visibleRowsFormattedWithTimeZone = useMemo(() => {
    return visibleRows.map((row) => ({
      ...row,
      ...(row?.lastReport && {
        lastReport: getDateWithGMTInfo(row.lastReport),
      }),
      ...(row?.nextReport && {
        nextReport: getDateWithGMTInfo(row.nextReport),
      }),
      ...(row?.startDate && {
        startDate: getDateWithGMTInfo(row.startDate),
      }),
    }));
  }, [visibleRows]);

  return (
    <Box className="table-with-pagination">
      {!simpleTable && (
        <Stack
          className="table-with-pagination__simple-table"
          spacing={0}
          direction="row"
        >
          {!rangeSelection && (title || description) && (
            <Stack className="table-with-pagination__simple-table-desc">
              <Typography
                className="table-with-pagination__simple-table-desc-text"
                variant="h3"
                sx={{ color: color && color }}
              >
                {title}
              </Typography>
              {description && (
                <p className="text-muted mb-2">
                  {`${description && description} : ${rowsLength}`}
                </p>
              )}
            </Stack>
          )}

          {!dataDescription && (
            <Stack
              className="table-with-pagination__simple-table-data-desc"
              direction="row"
              spacing={2}
            >
              {searchFields && searchFields.length > 0 && (
                <Box className="table-with-pagination__simple-table-data-desc-box">
                  <SearchInput
                    id="table-search-input"
                    isLoading={false}
                    performSearch={search}
                  />
                </Box>
              )}

              {rowsPerPageOptions}
              {switchComponent}
            </Stack>
          )}
        </Stack>
      )}

      {Object.keys(filters).length > 1 && enableFiltersOptions && (
        <Stack className="table-with-pagination__filters">
          <Stack
            className="table-with-pagination__filter-options"
            direction="row"
            spacing={1}
          >
            {printChip()}
          </Stack>

          <Stack className="table-with-pagination__filters-actions">
            <Button onClick={resetStates} disabled={!filtersLength > 0}>
              <Typography
                className="table-with-pagination__filters-actions-reset-btn"
                sx={{
                  color: !filtersLength ? 'cardLink.bgcolor' : 'primary.main',
                }}
              >
                Réinitialiser
              </Typography>
            </Button>
          </Stack>
        </Stack>
      )}

      {dataDescription && equipment && visibleRows && (
        <Stack className="table-with-pagination__data_desc" direction="row">
          {DataDescription({ equipment, visibleRows })}

          {rowsPerPageOptions}
        </Stack>
      )}

      <TableContainer className="table-with-pagination__table">
        {headerExtract && titleExtract && !hideExract && (
          <CSVLink
            headers={headerExtract}
            separator=";"
            filename={titleExtract
              .replaceAll(' ', '_')
              .concat(
                `_list_LoxConnect_${moment(new Date())
                  .format('YYYY-MM-DD HH:mm:ss')
                  .toString()
                  .replaceAll('-', '')
                  .replaceAll(':', '')
                  .replaceAll(' ', '-')}`
              )}
            data={visibleRowsFormattedWithTimeZone}
          >
            <Typography
              variant="body2Bold"
              fontSize="14px"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <Download />
              <Trans>Exporter la liste des </Trans> {titleExtract}
            </Typography>
          </CSVLink>
        )}
        <Table
          sx={{
            borderSpacing: !alternateBg && '0 8px !important',
            borderCollapse: !alternateBg && 'separate !important',
          }}
          aria-label="simple table"
          stickyHeader
        >
          {!isMobile && (
            <TableHeader
              filteredHeader={filteredHeader}
              enableFiltersOptions={enableFiltersOptions}
              columnsHeadersKey={columnsHeadersKey}
              isSorted={isSorted}
              permVisibleRows={permVisibleRows}
              setIsSorted={setIsSorted}
              headersSorted={headersSorted}
              setHeadersSorted={setHeadersSorted}
              setVisibleRows={setVisibleRows}
              columnsObject={columnsObject}
              checkboxHeader={checkboxHeader}
              filters={filters}
              handleSearchPopOverClick={handleSearchPopOverClick}
              popOverId={popOverId}
              openPopover={openPopover}
              anchorEl={anchorEl}
              handleClosePopOver={handleClosePopOver}
              checkBoxesStates={checkBoxesStates}
              handleCheckBoxChange={handleCheckBoxChange}
              setFilters={setFilters}
              setPermVisibleRows={setPermVisibleRows}
              visibleRows={visibleRows}
              setTempVisibleRows={setTempVisibleRows}
              parentFilters={parentFilters}
            />
          )}

          <TableBody className="table-with-pagination__table-body">
            {(currentPage
              ? visibleRows
              : visibleRows.slice(
                  page * rowsPerPage.value,
                  page * rowsPerPage.value + rowsPerPage.value
                )
            ).map((row) =>
              alternateBg ? (
                <StyledTableRowAlternateColor
                  key={`table-row-alternate-color-${row.id}`}
                  onClick={() => handleRowClick(row.id)}
                >
                  {cells(row, rowIdClicked)}
                </StyledTableRowAlternateColor>
              ) : (
                <StyledTableRow
                  key={`table-row-${row.id}`}
                  onClick={() => handleRowClick(row.id)}
                >
                  {cells(row, rowIdClicked)}
                </StyledTableRow>
              )
            )}

            {visibleRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={12}>{t`Aucun résultat trouvé`}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        className="table-with-pagination__pagination"
        spacing={0}
        direction="row"
      >
        <Pagination
          totalItemsCount={totalElements ?? visibleRows.length}
          itemsCountPerPage={rowsPerPage.value}
          page={page + 1}
          onChange={handleChangePage}
        />
      </Stack>
    </Box>
  );
};

export default TableWithPagination;

TableWithPagination.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  initFilters: PropTypes.shape({}),
  checkboxHeader: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      keys: PropTypes.arrayOf(PropTypes.string).isRequired,
      hideFilter: PropTypes.bool,
      hideSearch: PropTypes.bool,
      checkboxes: PropTypes.bool,
      keyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      keysNamesValues: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
      getStr: PropTypes.func,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.any.isRequired,
  cells: PropTypes.func.isRequired,
  optionsValue: PropTypes.arrayOf(PropTypes.number),
  alternateBg: PropTypes.bool,
  simpleTable: PropTypes.bool,
  enableFiltersOptions: PropTypes.bool,
  rangeSelection: PropTypes.bool,
  switchComponent: PropTypes.oneOfType([PropTypes.object]),
  searchFields: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    totalElements: PropTypes.number,
    handlePageChange: PropTypes.func,
  }),
  noSearch: PropTypes.bool,
  headerExtract: PropTypes.any,
  titleExtract: PropTypes.string,
  hideExract: PropTypes.bool,
};

TableWithPagination.defaultProps = {
  title: null,
  description: null,
  color: null,
  optionsValue: [5, 10, 20, 50],
  alternateBg: false,
  simpleTable: false,
  rangeSelection: false,
  switchComponent: null,
  searchFields: null,
  checkboxHeader: null,
  enableFiltersOptions: null,
  initFilters: null,
  pagination: {},
  noSearch: false,
  headerExtract: null,
  titleExtract: null,
  hideExract: false,
};
