import {
  getAgenciesByEmail,
  getContractsByEmail,
  getJobSitesByEmail,
  getMachinesByEmail,
} from '../../api/scope/Scope';
import { statusToMachineStatus } from '../../utils/equipment/Status/EquipmentStatus.service';
import { tryToParse, removeDuplicateByKeys } from '../../utils/utils';

// TODO - Map through data just once
export const getInternalAgencies = (email) =>
  getAgenciesByEmail(email).then((data) =>
    removeDuplicateByKeys(data, ['ag_iot_agency_id']).map((i) => parseAgency(i))
  );

export const getInternalContracts = (email) =>
  getContractsByEmail(email).then((data) =>
    removeDuplicateByKeys(data).map((i) => parseContract(i))
  );

export const getInternalJobSites = (email) =>
  getJobSitesByEmail(email).then((data) => data.map((i) => parseJobsite(i)));

export const getInternalMachines = (email) =>
  getMachinesByEmail(email).then((data) =>
    removeDuplicateByKeys(data, ['ma_iot_machine_id']).map((i) =>
      parseMachine(i)
    )
  );

const parseAgency = (item) => ({
  city: item.ag_city,
  email: item.ag_email,
  id: item.ag_iot_agency_id,
  lat: item.ag_lat,
  lon: item.ag_lon,
  name: item.ag_name,
  postalCode: item.ag_postal_code,
  rentalmanId: item.ag_rentalman_id,
  streetAddress: item.ag_street_address,
  streetNumber: item.ag_street_number,
});

const parseContract = (item) => ({
  agencyId: item.agency_id,
  clientId: item.client_id,
  clientName: item.client_name, // need
  constructionSiteName: item.construction_site_name, // need
  contactPhoneNumber: item.contact_phone_number, // need
  contractNumber: item.contract_number, //
  endRentalDateTime: item.end_rental_datetime,
  id: item.id,
  orderedBy: item.ordered_by, //
  pickUpAdress: item.pick_up_adress, //
  purchaseOrderNumber: item.purchase_order_number, //
  rentalmanId: item.rental_man_id,
});

const parseJobsite = (item) => ({
  city: item.job_city,
  customerNumber: item.job_customer_number,
  endDate: item.job_end_date,
  id: item.job_iot_job_site_id,
  lat: item.job_lat,
  lon: item.job_lon,
  name: item.job_name,
  rentalmanId: item.job_rentalman_id,
  startDate: item.job_start_date,
  streetAddress: item.job_street_address,
  streetNumber: item.job_street_number,
  zones: item.job_zones, // need
});

const parseMachine = (item) => ({
  address: `${item.ma_status_street_address ?? ''} ${
    item.ma_status_street_number ?? ''
  }`, //
  agencyId: item.ma_iot_agency_id,
  agencyName: item.ag_name || '', // need
  battery: item.ma_status_battery,
  brand: item.ma_brand,
  branch_code: item.ma_branch_code,
  city: item.ma_status_city,
  country: item.ma_status_country,
  description: item.ma_description,
  descriptions: tryToParse(item.ma_descriptions),
  distance: item.ma_status_distance,
  engineRunning: item.ma_status_engine_running,
  estimatedEnd: item.dctr_end_rental_date_time || null, //
  height: item.ma_height,
  id: item.ma_iot_machine_id,
  jobSiteId: item.ma_iot_job_site_id,
  jobSiteDesc: item.job_name,
  lastSyncTime: item.ma_last_sync_time,
  lat: item.ma_lat,
  lon: item.ma_lon,
  gpsFixTime: item.ma_status_gps_fix_time,
  length: item.ma_length,
  gbLong: item.ma_status_gb_long,
  gbLat: item.ma_status_gb_lat,
  gbLastSyncTime: item.ma_status_gb_last_sync_time,
  engineRunningDatetime: item.ma_engine_running_datetime,
  machineGroupId: item.ma_iot_machine_group_id,
  model: item.ma_model,
  name: item.ma_name,
  pim: tryToParse(item.ma_pim),
  purchaseOrderNumber: item.ctr_purchase_order_number || null, //
  contractId: item.ma_iot_contract_id || null,
  contractNumber: item.ctr_contract_number || null, //
  rentalStart: item.dctr_start_rental_date_time || null, //
  rentalmanId: item.ma_rentalman_id,
  serialNumber: item.ma_serial_number,
  weight: item.ma_weight,
  width: item.ma_width,
  zipCode: item.ma_status_postal_code,
  origin: item.ma_origin,
  imei: item.imei,
  tag: item.tag,
  startDate: item.startDate,
  mode: item.mode,
  requestedMode: item.requestedMode,
  nextReport: item.nextReport,
  lastReport: item.lastReport,
  comment: item.comment,

  equipmentStatusKey: item.ma_equipment_status_key || item.ma_status,
  equipmentStatusLib: statusToMachineStatus(
    item.ma_equipment_status_key || item.ma_status
  ).value,
});
