import { t } from '@lingui/macro';
import { Search } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../../../lib/apptheme';
import { getTrackerList } from '../../api/trackers/Trackers';
import { ReturnButton, TableWithPagination } from '../../components/molecules';
import { StyledTableCell } from '../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import { colorizeDate } from './utils';
import { useUserContext } from '../../contexts/UserContext/UserContext';

const generateCells = (row) => {
  return (
    <>
      <StyledTableCell>{t`${row.machine || '-'}`}</StyledTableCell>
      <StyledTableCell>{t`${row.agency || '-'}`}</StyledTableCell>
      <StyledTableCell>
        <Link
          to={`/cockpit/goobies/administration/${row.imei}`}
          relative="path"
        >{t`${row.imei}`}</Link>
      </StyledTableCell>
      <StyledTableCell>{t`${row.tag || '-'}`}</StyledTableCell>
      <StyledTableCell>{t`${row.mode || '-'}`}</StyledTableCell>
      <StyledTableCell>{t`${row.requestedMode || '-'}`}</StyledTableCell>
      <StyledTableCell>{formatDate(row.lastReport)}</StyledTableCell>
      <StyledTableCell style={{ color: colorizeDate(row.nextReport) }}>
        {formatNextDate(row.mode, row.nextReport)}
      </StyledTableCell>
    </>
  );
};

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = useCallback(() => {
    onSearch(searchTerm);
  }, [onSearch, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchTerm === '') {
      onSearch('');
    }
  }, [searchTerm, onSearch]);

  return (
    <div>
      <TextField
        label={t`Rechercher`}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        size="small"
      />
      <IconButton onClick={handleSearch}>
        <Search />
      </IconButton>
    </div>
  );
};

const HEADER = [
  t`Machine`,
  t`Agence`,
  t`IMEI`,
  t`Tag NFC`,
  t`Mode`,
  t`Requested Mode`,
  t`Dernier rapport`,
  t`Prochain rapport`,
];

const HEADER_EXTRACT = [
  { label: t`Machine`, key: 'machine' },
  { label: t`Statut`, key: 'equipmentStatus' },
  { label: t`Agence`, key: 'agency' },
  { label: t`Region`, key: 'region' },
  { label: t`Imei`, key: 'imei' },
  { label: t`Tag NFC`, key: 'tag' },
  { label: t`Date d'association`, key: 'startDate' },
  { label: t`Mode`, key: 'mode' },
  { label: t`Requested Mode`, key: 'requestedMode' },
  { label: t`Dernier rapport`, key: 'lastReport' },
  { label: t`Prochain rapport`, key: 'nextReport' },
  { label: t`Latitude`, key: 'gbLat' },
  { label: t`Longitude`, key: 'gbLong' },
  { label: t`Commentaire`, key: 'comment' },
];

const formatDate = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  return '-';
};

const formatNextDate = (mode, date) => {
  if (mode && date) {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  }
  return '-';
};

const COLUMNS_OBJECT = {
  Machine: {
    search: (e) => `${e.machine}`,
    value: (e) => e.machine,
  },
  Agence: {
    search: (e) => `${e.agency}`,
    value: (e) => e.agency,
  },
  IMEI: {
    search: (e) => `${e.imei}`,
    value: (e) => e.imei,
  },
  'Tag NFC': {
    search: (e) => `${e.tag}`,
    value: (e) => e.tag,
  },
  'Requested Mode': {
    search: (e) => `${e.requestedMode}`,
    value: (e) => e.requestedMode,
  },
  'Dernier rapport': {
    search: (e) => `${e.lastReport}`,
    value: (e) => e.lastReport,
  },
  'Prochain rapport': {
    search: (e) => formatNextDate(e.mode, e.nextReport),
    value: (e) => formatNextDate(e.mode, e.nextReport),
  },
};

const CockpitGoobiesAdministration = ({
  headers = HEADER,
  GenerateCells = generateCells,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    const result = await getTrackerList();
    const filteredResult = result.filter((item) => item.imei);
    const uniqueResult = filteredResult.reduce((accumulator, current) => {
      if (!accumulator.some((item) => item.imei === current.imei)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    setData(uniqueResult);
    setFilteredData(uniqueResult);
  };

  const user = useUserContext();

  useEffect(() => {
    if (user.codeRegion !== '01') {
      window.location.replace('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const onSearch = useCallback(
    (term) => {
      if (!term) {
        setFilteredData(data);
        return;
      }

      const lowerCaseTerm = term.toLowerCase();
      const filtered = data.filter((row) => {
        return Object.values(row).some((value) => {
          return (
            value && value.toString().toLowerCase().includes(lowerCaseTerm)
          );
        });
      });
      setFilteredData(filtered);
    },
    [data]
  );

  const onHandleFilterChange = (arrayData, mode) => {
    if (mode === 'ALL') {
      setFilteredData(data);
      return;
    }

    const result = arrayData.filter((item) => item.mode === mode);
    setFilteredData(result);
  };

  return (
    <Box>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ paddingBottom: '25px' }}
        >
          <ReturnButton label={t`Retour`} />
          <Stack direction="row">
            <FormControl
              size="small"
              style={{ width: '150px', marginRight: '10px' }}
            >
              <InputLabel shrink>Mode</InputLabel>
              <Select
                label="Mode"
                displayEmpty
                defaultValue="ALL"
                onChange={(e) => {
                  onHandleFilterChange(filteredData, e.target.value);
                }}
              >
                <MenuItem value="ALL">Show All</MenuItem>
                <MenuItem value={null}>None</MenuItem>
                <MenuItem value="SERVICE">Service</MenuItem>
                <MenuItem value="INIT">Init</MenuItem>
                <MenuItem value="STORAGE">Storage</MenuItem>
                <MenuItem value="TRACKING">Tracking</MenuItem>
              </Select>
            </FormControl>
            <SearchBar onSearch={onSearch} />
          </Stack>
        </Stack>
      </Stack>
      {filteredData ? (
        <Stack>
          <TableWithPagination
            title={t`Administration des goobies`}
            headers={headers}
            headerExtract={HEADER_EXTRACT}
            titleExtract="Goobies"
            rows={filteredData}
            cells={GenerateCells}
            optionsValue={[10, 15, 20, 50]}
            alternateBg
            enableFiltersOptions
            checkboxesStatesResp={undefined}
            columnsObject={COLUMNS_OBJECT}
            dataDescription={undefined}
            parentFilters={undefined}
            hideColumns={undefined}
          />
        </Stack>
      ) : (
        <Loading />
      )}
    </Box>
  );
};

export default CockpitGoobiesAdministration;
