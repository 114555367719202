/* eslint-disable no-undef */
export const generateDrawingManager = () =>
  new f4.map.drawing.DrawingManager({
    drawingMode: f4.map.drawing.OverlayType.MARKER,
    drawingControl: true,
    drawingControlOptions: {
      position: f4.map.ControlPosition.TOP_CENTER,
      drawingModes: [f4.map.drawing.OverlayType.POLYGON],
    },
    markerOptions: {
      icon: {
        url: '/static/examples/beachflag.png',
      },
    },
    polygonOptions: {
      strokeColor: '#f00',
      strokeWeight: 3,
    },
    polylineOptions: {
      strokeColor: '#00f',
      strokeWeight: 15,
      editable: true,
    },
  });
