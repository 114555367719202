/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { t } from '@lingui/macro';
import { useEffect, useMemo, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import iconJobSite from '../../../../assets/images/Icon_JobSite.svg';
import iconMachineUnknow from '../../../../assets/images/Icon_Machine_Unknow.svg';
import Pins_Machine_Empruntee_Marche from '../../../../assets/images/equipment-status/Pins_Machine_Empruntee_Marche.svg';
import iconGoobie from '../../../../assets/images/goobie/Pins_Machine_Antivol.svg';
import { findIconByMachineStatus } from '../../../../components/EquipmentStatusIcon/EquipmentStatusIcon.service';
import { useUserContext } from '../../../../contexts/UserContext/UserContext';
import useMap from '../../../../hooks/useMap/useMap';
import { findMachineStatus } from '../../../../utils/equipment/Status/EquipmentStatus.service';
import { getBound } from '../../../../utils/f4map/bound.utils';
import { smoothZoom } from '../../../../utils/f4map/utils';
import { useAppModeContext } from '../../../../utils/hooks';
import {
  copyTextToClipboard,
  getEquipmentDescription,
} from '../../../../utils/utils';
import { getAgencyIcon } from '../../utils';
import {
  MARKER_POPUP_CLOSE_DELAY,
  MARKER_POPUP_OPEN_DELAY,
} from '../Map.constants';
import renderEquipmentZone from '../Map.utils';
import AgencyMarkerPopup from '../MarkersPopup/AgencyMarkerPopup';
import JobSiteMarkerPopup from '../MarkersPopup/JobSiteMarkerPopup';
import MachineMarkerPopup from '../MarkersPopup/MachineMarkerPopup/MachineMarkerPopup';
import { DrawAllDepartements, DrawAllRegions } from '../Zones/MapZones';
import { saveRadiusCenter } from '../../../../api/machines/Machines';

const FleetMapAll = (props) => {
  const {
    machineId,
    locations,
    displayZone,
    showDraggableMarker,
    draggableMarkerCoordinates,
    updateDraggableMarkerCoordinates,
    displayRadius,
    radiusSize,
    mapClassName = '',
    hideZoneForIds = [],
    geoZone = {
      displayRegions: false,
      displayDepartments: false,
      geoZoneIds: [''],
      handleGeoZoneClicked: (geoZoneId) => {},
    },
    handleMarkerDrag = (coords) => {},
  } = props;

  const {
    displayRegions = false,
    displayDepartments = false,
    geoZoneIds = [],
    handleGeoZoneClicked = (geoZoneId) => {},
  } = geoZone;

  const history = useHistory();

  const user = useUserContext();
  const { isInternalAppMode } = useAppModeContext();

  const { search } = useLocation();

  const zoomOn = useMemo(() => new URLSearchParams(search), [search]).get(
    'zoomOn'
  );

  const {
    map,
    MapCanvas,
    setMapElements,
    resetMapElements,
    addPolygon,
    resetCircles,
  } = useMap({
    mapClassName,
  });

  let click = false;
  const openMarkerTimeout = useRef(null);

  const handleMachineClick = (machineId, page) => {
    history.push(`/equipments/${page}/${machineId}`);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (displayRegions || displayDepartments) && resetMapElements();
  }, [displayRegions, displayDepartments]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const markers = [];
    const polygons = [];
    const circles = [];

    if (!map) return;

    resetMapElements();
    resetCircles();
    if (!locations || locations.length === 0) return;

    if (displayRegions) {
      DrawAllRegions(map, geoZoneIds, handleGeoZoneClicked, addPolygon);
      map.setZoom(7);
    }
    if (displayDepartments) {
      DrawAllDepartements(map, geoZoneIds, handleGeoZoneClicked, addPolygon);
      map.setZoom(7);
    }

    try {
      const markersArr = [];

      locations.forEach((el) => {
        let equipLat = null;
        let equipLon = null;

        if (!isInternalAppMode && el.state === 'goobie') return;

        if (el.state === 'goobie') {
          equipLat = el.gbLat || '0';
          equipLon = el.gbLong || '0';
        } else if (el.state === 'Machines' || el.state === 'Agences') {
          equipLat = el.lat || '0';
          equipLon = el.lon || '0';
        }

        if (Math.trunc(equipLat) !== 0 && Math.trunc(equipLon) !== 0) {
          const myLatlng2 = new f4.map.LatLng(equipLat, equipLon);

          let title;
          let icon;
          let contentString;

          switch (el.state) {
            case 'goobie': {
              if (
                el.gbLastSyncTime === null ||
                !moment(el.gbLastSyncTime).isValid()
              ) {
                break;
              }

              const machineStatus = findMachineStatus(
                el.gbLastSyncTime,
                el.engineRunning,
                isInternalAppMode ? el.equipmentStatusKey : undefined,
                el.agencyOwnerId,
                el.agencyId,
                user.agencyId,
                user.mergedProfil
              );

              icon = iconGoobie;
              title = `${getEquipmentDescription(el, el.model)} (${
                el.rentalmanId
              })`;

              contentString = renderToString(
                <MachineMarkerPopup
                  machine={el}
                  machineStatus={machineStatus}
                  title={title}
                />
              );

              break;
            }
            case 'Machines': {
              if (el.gpsFixTime === null || !moment(el.gpsFixTime).isValid()) {
                break;
              }

              const machineStatus = findMachineStatus(
                el.gpsFixTime,
                el.engineRunning,
                isInternalAppMode ? el.equipmentStatusKey : undefined,
                el.agencyOwnerId,
                el.agencyId,
                user.agencyId,
                user.mergedProfil,
                true
              );

              icon = findIconByMachineStatus(machineStatus);
              title = `${getEquipmentDescription(el, el.model)} (${
                el.rentalmanId
              })`;

              contentString = renderToString(
                <MachineMarkerPopup
                  machine={el}
                  machineStatus={machineStatus}
                  title={title}
                  isInternalAppMode={isInternalAppMode}
                />
              );

              break;
            }
            case 'Chantiers': {
              title = el.name;
              icon = iconJobSite;

              contentString = renderToString(
                <JobSiteMarkerPopup jobSite={el} title={title} />
              );

              break;
            }
            case 'Agences': {
              title = getEquipmentDescription(el, el.name);

              const openingTimes = {
                morning: {
                  opening: '06h30',
                  openingDate: new Date().setHours('06', '30'),
                  closing: '12h00',
                  closingDate: new Date().setHours('12', '00'),
                },
                afternoon: {
                  opening: '13h30',
                  openingDate: new Date().setHours('13', '30'),
                  closing: '14h00',
                  closingDate: new Date().setHours('18', '00'),
                },
                open: t`Ouvert jusqu'à`,
                close: t`Fermé jusqu'à`,
              };

              const actualTime = new Date();

              // Need to simplify this ^^'
              if (
                (actualTime > openingTimes.morning.openingDate &&
                  actualTime < openingTimes.morning.closingDate) ||
                (actualTime > openingTimes.afternoon.openingDate &&
                  actualTime < openingTimes.afternoon.closingDate)
              ) {
                icon = getAgencyIcon(el?.activityLevel1Label, true);
              } else {
                icon = getAgencyIcon(el?.activityLevel1Label, false);
              }

              contentString = renderToString(
                <AgencyMarkerPopup agency={el} title={title} />
              );

              break;
            }
            default: {
              console.error('Unknown equipment state.');
              icon = iconMachineUnknow;
            }
          }

          const addListenerToLinkButton = () => {
            const links = [
              {
                name: 'Machine',
                page: 'detail',
              },
              {
                name: 'JobSite',
                page: 'jobsite',
              },
              {
                name: 'Agency',
                page: 'agency',
              },
            ];

            // Link to detail page on title click
            links.forEach((link) => {
              const elements = document.querySelectorAll(
                `.linkToDetailsPageEquipment${link.name}`
              );
              elements.forEach((el) => {
                try {
                  el.addEventListener('click', (e) => {
                    handleMachineClick(e.srcElement.id, link.page);
                  });
                } catch (e) {
                  console.error(e);
                }
              });
            });

            try {
              document
                .getElementById(`copy-coordinates-button-${el.id}`)
                .addEventListener('click', () => {
                  const textToCopy = `${el.lat},${el.lon}`;
                  copyTextToClipboard(textToCopy);
                });
            } catch (e) {
              console.error(e);
            }

            // Info Link to Agency Detail Page
            const infoAgency = document.querySelectorAll(
              `.linkToDetailsPageInfoIcon`
            );
            infoAgency.forEach((el) => {
              try {
                el.addEventListener('click', (e) => {
                  handleMachineClick(
                    (e.srcElement || e.target).id,
                    links.find((e) => e.name === el.dataset.name).page
                  );
                });
              } catch (e) {
                console.error(e);
              }
            });

            const resetLocation = document.querySelectorAll(
              `.linkToLocationInfoIcon`
            );
            resetLocation.forEach((el) => {
              try {
                el.addEventListener('click', (e) => {
                  const position = (e.srcElement || e.target).id.split(',');

                  map.setCenter(
                    new f4.map.LatLng(
                      position[0],
                      position[position.length - 1]
                    )
                  );
                  map.setZoom(18);

                  infowindow.close(map, marker);
                });
              } catch (e) {
                console.error(e);
              }
            });
          };

          const infowindow = new f4.map.InfoWindow({
            content: contentString,
          });

          const marker = new f4.map.Marker({
            position: myLatlng2,
            map,
            title,
            icon: {
              url: icon,
              scaledSize: new f4.map.Size(40, 50),
            },
            draggable: !!el.isDraggable,
          });

          markers.push(marker);

          if (
            displayZone &&
            typeof showDraggableMarker !== 'boolean' &&
            !hideZoneForIds.includes(el.state)
          ) {
            renderEquipmentZone(
              el,
              map,
              myLatlng2,
              polygons,
              circles,
              radiusSize
            );
          }

          markersArr.push({
            lat: equipLat,
            lon: equipLon,
          });

          if (el.id === zoomOn) {
            map.setZoom(18);
            map.setCenter(marker.getPosition());
          }

          f4.map.event.addListener(marker, 'click', () => {
            smoothZoom(map, 18);

            map.setCenter(marker.getPosition());
            // eslint-disable-next-line react-hooks/exhaustive-deps
            click = true;
            infowindow.open(map, marker);

            addListenerToLinkButton();
          });

          f4.map.event.addListener(map, 'zoom_changed', () => {
            const zoomLevel = map.getZoom();
            if (zoomLevel !== 18) {
              click = false;
            }
          });

          f4.map.event.addListener(marker, 'mouseover', () => {
            if (!click) {
              openMarkerTimeout.current = setTimeout(
                () => infowindow.open(map, marker),
                MARKER_POPUP_OPEN_DELAY
              );
            }

            // setTimeout because to be able to add the listeners the popup should be open
            setTimeout(
              () => addListenerToLinkButton(),
              MARKER_POPUP_OPEN_DELAY
            );
          });

          f4.map.event.addListener(marker, 'mouseout', () => {
            clearTimeout(openMarkerTimeout.current);

            try {
              const popUp = document.querySelector(
                `.infowindow-${el.state}-${el.id}`
              );

              let isMouseOverPopUp = false;

              if (popUp) {
                try {
                  popUp.addEventListener('mouseover', () => {
                    isMouseOverPopUp = true;
                  });
                } catch (e) {
                  console.error(e);
                }
                try {
                  popUp.addEventListener('mouseout', () => {
                    isMouseOverPopUp = false;
                  });
                } catch (e) {
                  console.error(e);
                }
              }

              // doesn't close the popup after marker click
              if ((!click && !isMouseOverPopUp) || !popUp)
                setTimeout(
                  () => infowindow.close(map, marker),
                  MARKER_POPUP_CLOSE_DELAY
                );
            } catch (e) {
              console.error(e);
            }
          });
        }
      });

      const machineLocation =
        draggableMarkerCoordinates &&
        Object.keys(draggableMarkerCoordinates).length === 0
          ? locations.filter((f) => f.state === 'Machines')[0]
          : draggableMarkerCoordinates;

      if (machineLocation?.lat && machineLocation?.lon) {
        const myLatlng = new f4.map.LatLng(
          machineLocation.lat,
          machineLocation.lon
        );

        if (displayZone) {
          renderEquipmentZone(
            machineLocation,
            map,
            myLatlng,
            polygons,
            circles,
            radiusSize
          );
        } else {
          circles.forEach((c) => map.removeCircle(c));
        }

        if (showDraggableMarker) {
          const marker = new f4.map.Marker({
            alwaysInFront: true,
            position: myLatlng,
            map,
            title: 'Zone',
            icon: {
              url: Pins_Machine_Empruntee_Marche,
              scaledSize: new f4.map.Size(45, 55),
            },
            draggable: true,
          });
          markers.push(marker);

          // WARNING
          // The center will be updated at dragend, but the updated data won't be fetced
          // If the user hide the marker and show again the old lat/long is going to be used
          // Need to change the Equipment Details architecture to be able to do it
          f4.map.event.addListener(marker, 'dragend', () => {
            const coords = marker.getPosition();
            // eslint-disable-next-line no-underscore-dangle
            const myLatlng = new f4.map.LatLng(coords._lat, coords._lng);
            // eslint-disable-next-line no-underscore-dangle
            // handleMarkerDrag({ lat: coords._lat, lon: coords._lng });
            saveRadiusCenter({
              machineId,
              geoType: 'GTCIR',
              // eslint-disable-next-line no-underscore-dangle
              lat: coords._lat,
              // eslint-disable-next-line no-underscore-dangle
              lon: coords._lng,
              radius: radiusSize,
            });
            map.setCenter(coords);
            if (displayZone) {
              resetCircles();
              renderEquipmentZone(
                machineLocation,
                map,
                myLatlng,
                polygons,
                circles,
                radiusSize
              );
            }
          });
        }
      }

      if (!zoomOn && markersArr.length > 0) {
        const bounds = getBound(markersArr);
        map.panToBounds(bounds);
      }
    } catch (e) {
      console.error(e);
    }

    setMapElements({ markers, polygons, circles });
  }, [
    map,
    zoomOn,
    locations,
    displayZone,
    showDraggableMarker,
    displayRegions,
    displayDepartments,
    geoZoneIds,
    radiusSize,
  ]);

  return MapCanvas;
};

export default FleetMapAll;
